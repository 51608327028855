import React, { useEffect, useState } from "react";
import Modal from "../../common/modal/modal";
import PageHeader from "../../common/pageheader/pageheader";
import Table from "../../common/table/table";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import Loader from "../../common/loader/loader";
import { showAlert } from "../../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import AddFacultyForm from "./addfacultyform";
import {connect} from "react-redux";

function Faculty(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setIsFormLoading] = useState('off');
    const [datatable, setDatatable] = useState({
        columns: [
            {
                label: "S/N",
                field: "sn",
            },
            {
                label: "Faculty Name",
                field: "FacultyName",
            },
            {
                label: "Faculty Code",
                field: "FacultyCode",
            },
            {
                label: "Faculty Dean",
                field: "FacultyDean",
            },
            {
                label: "Deputy Dean",
                field: "FacultyDeputyDean",
            },
            {
                label: "IsAcademic",
                field: "IsAcademic",
            },
            {
                label: "IsAwardDegree",
                field: "IsAwardDegree",
            },
            {
                label: "Action",
                field: "action",
            },

        ],
        rows: [],
    });
    const [academicStaff, setAcademicStaff] = useState([])

    const [createFaculty, setCreateFaculty] = useState({
        FacultyName: "",
        FacultyCode: "",
        FacultyDean: "",
        FacultyDeputyDean: "",
        IsAcademic: 1,
        IsAwardDegree: 1,
        EntryID: "",
        InsertedBy: `${props.loginData[0].FirstName} ${props.loginData[0].MiddleName} ${props.loginData[0].Surname}`
    });

    const getAcademicStaff = async () => {
        await axios
            .get(`${serverLink}staff/academics/department/academic-staff/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setAcademicStaff(result.data)
                }
            })
    }

    const getFaculty = async () => {
        await axios
            .get(`${serverLink}staff/academics/faculty/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    let rows = [];
                    result.data.map((faculty, index) => {
                        rows.push({
                            sn: index + 1,
                            FacultyName: faculty.FacultyName ?? "N/A",
                            FacultyCode: faculty.FacultyCode ?? "N/A",
                            FacultyDean: faculty.FacultyDean ?? "N/A",
                            FacultyDeputyDean: faculty.FacultyDeputyDean ?? "N/A",
                            IsAcademic: faculty.IsAcademic === 1 ? 'Academic' : 'Non-Academic',
                            IsAwardDegree: faculty.IsAwardDegree === 1 ? 'Yes' : 'No',
                            action: (
                                <button
                                    className="btn btn-sm btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_general"
                                    onClick={() =>
                                        setCreateFaculty({
                                            FacultyName: faculty.FacultyName,
                                            FacultyCode: faculty.FacultyCode,
                                            FacultyDean: faculty.FacultyDean,
                                            FacultyDeputyDean: faculty.FacultyDeputyDean,
                                            IsAcademic: faculty.IsAcademic,
                                            IsAwardDegree: faculty.IsAwardDegree,
                                            EntryID: faculty.EntryID,
                                            InsertedBy: `${props.loginData[0].FirstName} ${props.loginData[0].MiddleName} ${props.loginData[0].Surname}`
                                        })
                                    }
                                >
                                    <i className="fa fa-pen" />
                                </button>
                            ),
                        });
                    });

                    setDatatable({
                        ...datatable,
                        columns: datatable.columns,
                        rows: rows,
                    });
                }

                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const onEdit = (e) => {
        setCreateFaculty({
            ...createFaculty,
            [e.target.id]: e.target.value,
        });
    };

    const onSubmit = async () => {
        if (createFaculty.FacultyName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter the faculty name", "error");
            return false;
        }
        if (createFaculty.FacultyCode.trim() === "") {
                    showAlert("EMPTY FIELD", "Please enter the faculty code", "error");
            return false;
        }
        if (createFaculty.FacultyDean.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter the faculty dean", "error");
            return false;
        }


        if (createFaculty.EntryID === "") {
            setIsFormLoading('on')
            await axios
                .post(`${serverLink}staff/academics/faculty/add`, createFaculty)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Faculty Added Successfully");
                        setIsFormLoading('off')
                        getFaculty();
                        document.getElementById("closeModal").click()
                        setCreateFaculty({
                            ...createFaculty,
                            FacultyName: "",
                            FacultyCode: "",
                            FacultyDean: "",
                            FacultyDeputyDean: "",
                            IsAcademic: 1,
                            EntryID: 1,
                        });
                    } else if (result.data.message === "exist") {
                        showAlert("FACULTY EXIST", "Faculty already exist!", "error");
                    } else {
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        } else {
            setIsFormLoading('on')
            await axios
                .patch(`${serverLink}staff/academics/faculty/update`, createFaculty)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Faculty Updated Successfully");
                        setIsFormLoading('off')
                        getFaculty();
                        document.getElementById("closeModal").click()
                        setCreateFaculty({
                            ...createFaculty,
                            FacultyName: "",
                            FacultyCode: "",
                            FacultyDean: "",
                            FacultyDeputyDean: "",
                            IsAcademic: 1,
                            IsAwardDegree: 1,
                            EntryID: "",
                        });
                    } else {
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }
    };

    useEffect(() => {
        getAcademicStaff();
        axios
            .get(`${serverLink}staff/academics/faculty/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    let rows = [];
                    result.data.map((faculty, index) => {
                        rows.push({
                            sn: index + 1,
                            FacultyName: faculty.FacultyName ?? "N/A",
                            FacultyCode: faculty.FacultyCode ?? "N/A",
                            FacultyDean: faculty.FacultyDean ?? "N/A",
                            FacultyDeputyDean: faculty.FacultyDeputyDean ?? "N/A",
                            IsAcademic: faculty.IsAcademic === 1 ? 'Academic' : 'Non-Academic',
                            IsAwardDegree: faculty.IsAwardDegree === 1 ? 'Yes' : 'No',
                            action: (
                                <button
                                    className="btn btn-sm btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_general"
                                    onClick={() =>
                                        setCreateFaculty({
                                            FacultyName: faculty.FacultyName,
                                            FacultyCode: faculty.FacultyCode,
                                            FacultyDean: faculty.FacultyDean,
                                            FacultyDeputyDean: faculty.FacultyDeputyDean,
                                            IsAcademic: faculty.IsAcademic,
                                            IsAwardDegree: faculty.IsAwardDegree,
                                            EntryID: faculty.EntryID,
                                            InsertedBy: `${props.loginData[0].FirstName} ${props.loginData[0].MiddleName} ${props.loginData[0].Surname}`
                                        })
                                    }
                                >
                                    <i className="fa fa-pen" />
                                </button>
                            ),
                        });
                    });

                    setDatatable({
                        ...datatable,
                        columns: datatable.columns,
                        rows: rows,
                    });
                }

                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }, []);

    return isLoading ? (
        <Loader />
    ) : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Manage Faculty"}
                items={["Academics", "Faculty"]}
            />
            <div className="flex-column-fluid">
                <div className="card">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title" />
                        <div className="card-toolbar">
                            <div
                                className="d-flex justify-content-end"
                                data-kt-customer-table-toolbar="base"
                            >
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_general"
                                    onClick={() =>
                                        setCreateFaculty({
                                            ...createFaculty,
                                            FacultyName: "",
                                            FacultyCode: "",
                                            FacultyDean: "",
                                            FacultyDeputyDean: "",
                                            IsAcademic: 1,
                                            IsAwardDegree: 1,
                                            EntryID: "",
                                        })
                                    }
                                >
                                    Add Faculty
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <Table data={datatable} />
                    </div>
                </div>
                <Modal title={"Add Faculty"}>
                    <AddFacultyForm data={createFaculty} isFormLoading={isFormLoading} onEdit={onEdit} staffList={academicStaff} onSubmit={onSubmit}/>
                </Modal>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(Faculty);

