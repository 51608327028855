import axios from "axios";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../common/loader/loader";
import PageHeader from "../../common/pageheader/pageheader";
import ReportTable from "../../common/table/report_table";
import { toast } from "react-toastify";
import { serverLink } from "../../../resources/url";

const ByFaculty = (props) => {
  const staffId = props.login[0].StaffID;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tableHeight, setTableHeight] = useState("600px");
  const [canSeeReport, setCanSeeReport] = useState(false);

  const columns = [
    "S/N",
    "Faculty Name",
    "Department Name",
    "ModuleCode",
    "Module Name",
    "Course Code",
    "Course Level",
    "Course Semester",
    "CreditLoad",
    "Module Type",
  ];

  useEffect(() => {
    setIsLoading(true);
    async function getCourse() {
      await axios
        .get(`${serverLink}academics/module-running/by-department/${staffId}`)
        .then((res) => {
          const result = res.data;
          if (result.length > 0) {
            let rows = [];
            result.map((item, index) => {
              rows.push([
                index + 1,
                item.FacultyName,
                item.DepartmentName,
                item.ModuleCode,
                item.ModuleName,
                item.CourseCode,
                item.CourseLevel,
                item.CourseSemester,
                item.CreditLoad,
                item.ModuleType,
              ]);
            });
            setTableHeight(result.length > 100 ? "1000px" : "600px");
            setData(rows);
            setCanSeeReport(true);
          } else {
            toast.error("There are no modules running for this Faculty.");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error("NETWORK ERROR");
        });
    }
    getCourse();
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="d-flex flex-column flex-row-fluid">
      <PageHeader
        title={"Module Running By Faculty"}
        items={["Academics", "Module Running", "Module Running By Faculty"]}
      />
      <div className="flex-column-fluid">
        <div className="card">
          <div className="card-body pt-2">
            {canSeeReport ? (
              <div className="row">
                <div className="col-md-12 mt-5">
                  {
                    <ReportTable
                      title={`Module Running By Faculty`}
                      columns={columns}
                      data={data}
                      height={tableHeight}
                    />
                  }
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.LoginDetails,
  };
};
export default connect(mapStateToProps, null)(ByFaculty);
