import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { serverLink } from "../../../resources/url";
import Loader from "../../common/loader/loader";
import PageHeader from "../../common/pageheader/pageheader";
import Table from "../../common/table/table";
import Moment from "react-moment";
import { encryptData } from "../../common/cryptography/cryptography";
import { formatDateAndTime } from "../../../resources/constants";
import { showAlert } from "../../common/sweetalert/sweetalert";
import { toast } from "react-toastify";

function RegistrationDashboard(props) {
  const [appList, setAppList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [ugApproveCount, setUgApproveCount] = useState([]);
  const [approveRejectCount, setApproveRejectCount] = useState([]);
  const [active, setActive] = useState("menu-link");
  const [showForm, setshowForm] = useState(true);
  const [Applicant, setAPplicant] = useState([]);
  const [AppDocuments, setAppDocuments] = useState([]);
  const [documentList, setDocumentsList] = useState([]);
  const [applicantDet, setApplicantDet] = useState([]);

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Name",
        field: "name",
      },
      {
        label: "Application Type",
        field: "type",
      },
      {
        label: "ApplicationID",
        field: "appID",
      },
      {
        label: "Course",
        field: "course",
      },
      {
        label: "Date Applied",
        field: "date",
      },
      {
        label: "",
        field: "action",
      },
    ],
    rows: [],
  });

  const [app, setApp] = useState({
    applicantionid: "",
    File: "",
    DocumentName: ""
  })

  useEffect(() => {
    async function getApplicationList() {
      await axios
        .get(`${serverLink}registration/admissions/list/`)
        .then((response) => {
          setTotalCount(response.data.length);
          setAppList(response.data);
          if (response.data.length > 0) {
            let rows = [];
            response.data.map((applicant, index) => {
              rows.push({
                sn: index + 1,
                name:
                  applicant.FirstName +
                  " " +
                  applicant.MiddleName +
                  " " +
                  applicant.Surname,
                type: applicant.ApplicationType,
                date: (
                  <Moment format="YYYY-MM-DD" date={applicant.InsertedDate} />
                ),
                appID: applicant.AppID,
                course: applicant.CourseName,
                action:
                  applicant.ApplicationType === "undergraduate" ? (
                    <a
                      href={`/application-processing-ug/${applicant.EntryID}`}
                      className="btn btn-sm btn-primary"
                    >
                      View
                    </a>
                  ) : (
                    <a
                      href={`/application-processing-pg/${applicant.EntryID}`}
                      className="btn btn-sm btn-primary"
                    >
                      View
                    </a>
                  ),
              });
            });
            setDatatable({
              ...datatable,
              columns: datatable.columns,
              rows: rows,
            });
          }
          setIsLoading(false);
          setActive("menu-link active");
        })
        .catch((ex) => {
          console.error(ex);
        });
    }
    getDocumentsList();
    getApplicationList();
  }, []);

  const getUGList = async () => {
    setshowForm(true)
    setIsLoading(true);
    await axios
      .get(`${serverLink}registration/admissions/ug`)
      .then((response) => {
        if (response.data.length > 0) {
          let rows = [];
          response.data.map((applicant, index) => {
            rows.push({
              sn: index + 1,
              name:
                applicant.FirstName +
                " " +
                applicant.MiddleName +
                " " +
                applicant.Surname,
              type: applicant.ApplicationType,
              date: (
                <Moment format="YYYY-MM-DD" date={applicant.InsertedDate} />
              ),
              appID: applicant.AppID,
              course: applicant.CourseName,
              action:
                applicant.ApplicationType === "undergraduate" ? (
                  <a
                    href={`/application-processing-ug/${applicant.EntryID}`}
                    className="btn btn-sm btn-primary"
                  >
                    View
                  </a>
                ) : (
                  <a
                    href={`/application-processing-pg/${applicant.EntryID}`}
                    className="btn btn-sm btn-primary"
                  >
                    View
                  </a>
                ),
            });
          });
          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const getPGList = async () => {
    setshowForm(true)
    setIsLoading(true);
    await axios
      .get(`${serverLink}registration/admissions/pg`)
      .then((response) => {
        if (response.data.length > 0) {
          let rows = [];
          response.data.map((applicant, index) => {
            rows.push({
              sn: index + 1,
              name:
                applicant.FirstName +
                " " +
                applicant.MiddleName +
                " " +
                applicant.Surname,
              type: applicant.ApplicationType,
              date: (
                <Moment format="YYYY-MM-DD" date={applicant.InsertedDate} />
              ),
              appID: applicant.AppID,
              course: applicant.CourseName,
              action:
                applicant.ApplicationType === "undergraduate" ? (
                  <a
                    href={`/application-processing-ug/${applicant.EntryID}`}
                    className="btn btn-sm btn-primary"
                  >
                    View
                  </a>
                ) : (
                  <a
                    href={`/application-processing-pg/${applicant.EntryID}`}
                    className="btn btn-sm btn-primary"
                  >
                    View
                  </a>
                ),
            });
          });
          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const showDocumentsPage = () => {

  }
  const getAllApplicantList = async () => {
    setIsLoading(true);
    await axios
      .get(`${serverLink}registration/admissions/list/`)
      .then((response) => {
        if (response.data.length > 0) {
          let rows = [];
          response.data.map((applicant, index) => {
            rows.push({
              sn: index + 1,
              name:
                applicant.FirstName +
                " " +
                applicant.MiddleName +
                " " +
                applicant.Surname,
              type: applicant.ApplicationType,
              date: (
                <Moment format="YYYY-MM-DD" date={applicant.InsertedDate} />
              ),
              appID: applicant.AppID,
              course: applicant.CourseName,
              action:
                applicant.ApplicationType === "undergraduate" ? (
                  <a
                    href={`/application-processing-ug/${applicant.EntryID}`}
                    className="btn btn-sm btn-primary"
                  >
                    View
                  </a>
                ) : (
                  <a
                    href={`/application-processing-pg/${applicant.EntryID}`}
                    className="btn btn-sm btn-primary"
                  >
                    View
                  </a>
                ),
            });
          });
          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const getListCount = async () => {
    setIsLoading(true);
    await axios
      .get(`${serverLink}registration/admissions/approve-reject-list`)
      .then((response) => {
        if (response.data.length > 0) {
          setApproveRejectCount(response.data);
        }
        setIsLoading(false);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };
  useEffect(() => {
    getListCount();
  }, []);

  const getUGListApprove = async () => {
    setshowForm(true)
    setIsLoading(true);
    await axios
      .get(`${serverLink}registration/admissions/approve-list`)
      .then((response) => {
        if (response.data.length > 0) {
          setUgApproveCount(response.data);
          let rows = [];
          response.data.map((applicant, index) => {
            rows.push({
              sn: index + 1,
              name:
                applicant.FirstName +
                " " +
                applicant.MiddleName +
                " " +
                applicant.Surname,
              type: applicant.ApplicationType,
              date: (
                <Moment format="YYYY-MM-DD" date={applicant.InsertedDate} />
              ),
              appID: applicant.AppID,
              course: applicant.CourseName,
              action:
                applicant.ApplicationType === "undergraduate" ? (
                  <a
                    href={`/application-processing-ug/${applicant.EntryID}`}
                    className="btn btn-sm btn-primary"
                  >
                    View
                  </a>
                ) : (
                  <a
                    href={`/application-processing-pg/${applicant.EntryID}`}
                    className="btn btn-sm btn-primary"
                  >
                    View
                  </a>
                ),
            });
          });
          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const getUGListReject = async () => {
    setshowForm(true)
    setIsLoading(true);
    await axios
      .get(`${serverLink}registration/admissions/reject-list`)
      .then((response) => {
        if (response.data.length > 0) {
          setUgApproveCount(response.data);
          let rows = [];
          response.data.map((applicant, index) => {
            rows.push({
              sn: index + 1,
              name:
                applicant.FirstName +
                " " +
                applicant.MiddleName +
                " " +
                applicant.Surname,
              type: applicant.ApplicationType,
              date: (
                <Moment format="YYYY-MM-DD" date={applicant.InsertedDate} />
              ),
              appID: applicant.AppID,
              course: applicant.CourseName,
              action:
                applicant.ApplicationType === "undergraduate" ? (
                  <a
                    href={`/application-processing-ug/${applicant.EntryID}`}
                    className="btn btn-sm btn-primary"
                  >
                    View
                  </a>
                ) : (
                  <a
                    href={`/application-processing-pg/${applicant.EntryID}`}
                    className="btn btn-sm btn-primary"
                  >
                    View
                  </a>
                ),
            });
          });
          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const getAppDocuments = async () => {
    if(app.applicantionid===""){
      setAppDocuments([]);
      showAlert("Error","please enter application id", "error");
      return;
    }
    try {
      await axios.get(`${serverLink}registration/admissions/application-documents/${app.applicantionid}`)
        .then((res) => {
          if (res.data.length > 0) {
            setAppDocuments(res.data);
            const applicant = appList.filter(x => parseInt(x.AppID) === parseInt(app.applicantionid))
            setApplicantDet(applicant)
          }
          else {
            setAppDocuments([])
          }
        })
    } catch (e) {

    }
  }

  const getDocumentsList = async () => {
    try {
      await axios.get(`${serverLink}registration/admissions/staff/documents/list`)
        .then((res) => {
          setDocumentsList(res.data)
        })
    } catch (e) {

    }
  }

  const UploadFile = async () => {
    if (app.applicantionid === "" || app.DocumentName === "" || app.File === "") {
      toast.error('please fill all fields');
      return;
    }
    try {
      let formData = new FormData();
      formData.append("File", app.File);
      formData.append("ApplicationID", app.applicantionid);
      formData.append("DocumentType", app.DocumentName)
      await axios.post(`${serverLink}registration/admissions/upload-doc`, formData).then((res) => {
        if (res.data.message === "uploaded") {
          getAppDocuments();
          showAlert("SUCCESS", "document uploaded", "success");
        } else {
          toast.error("please try again...")
        }
      })
    } catch (e) {
    }

  }
  //ug list of applicants
  const ugCount = appList.filter(
    (app) => app.ApplicationType === "undergraduate"
  );

  //pg list of applicants
  const pgCount = appList.filter(
    (app) => app.ApplicationType === "postgraduate"
  );

  const rejectList = approveRejectCount.filter((type) => type.Status === 3);
  const approveList = approveRejectCount.filter((type) => type.Status === 2);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="d-flex flex-column flex-row-fluid">
      <PageHeader title={"Admissions"} items={["Registration", "Admissions"]} />
      <div className="flex-column-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10 m-grid-col-md-3">
              <div className="menu-item mb-3">
                <span className={active}>
                  <span
                    className="menu-title fw-bolder"
                    onClick={() => {
                      setshowForm(true)
                    }}
                  >
                    Pending
                  </span>
                  <span className="badge badge-success">{totalCount}</span>
                </span>
              </div>
              <div className="menu-item mb-3">
                <span className="menu-link " onClick={getUGList}>
                  <span className="menu-title fw-bolder">Undergraguate</span>

                  <span className="badge badge-warning">{ugCount.length}</span>
                </span>
              </div>
              <div className="menu-item mb-3">
                <span className="menu-link " onClick={getPGList}>
                  <span className="menu-title fw-bolder">Postgraduate</span>
                  <span className="badge badge-primary">{pgCount.length}</span>
                </span>
              </div>
              <div className="menu-item mb-3">
                <span className="menu-link ">
                  <span
                    className="menu-title fw-bolder"
                    onClick={getUGListApprove}
                  >
                    Approved
                  </span>
                  <span className="badge badge-success">
                    {approveList.length}
                  </span>
                </span>
              </div>
              <div className="menu-item mb-3">
                <span className="menu-link ">
                  <span
                    className="menu-title fw-bolder"
                    onClick={getUGListReject}
                  >
                    Rejected
                  </span>
                  <span className="badge badge-danger">
                    {rejectList.length}
                  </span>
                </span>
              </div>
              <div className="menu-item mb-3">
                <span className="menu-link ">
                  <span
                    className="menu-title fw-bolder"
                    onClick={() => {
                      setshowForm(false)
                    }}
                  >
                    Manage Applicants Documents
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="card-body pt-0">
              {
                showForm === true ? <Table data={datatable} />
                  :
                  <div className="row col-md-12">
                    <div className="col-md-8">
                      <input value={app.applicantionid} className="form-control" onChange={(e) => {
                        setApp({
                          ...app,
                          applicantionid: e.target.value
                        })
                      }} placeholder="enter applicantion id" />
                    </div>
                    <div className="col-md-4">
                      <button onClick={getAppDocuments} className="btn btn-sm btn-primary" >Search Applicantion</button>
                    </div>

                    <div className="table table-responsive">
                      {
                        AppDocuments.length > 0 &&
                        <>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <td>SN</td>
                                <td>Title</td>
                                <td>Link</td>
                                <td>Inserted On</td>
                              </tr>
                            </thead>
                            {
                              AppDocuments.length > 0 ?
                                <tbody>
                                  {
                                    AppDocuments.map((x, y) => {
                                      return (
                                        <tr key={y}>
                                          <td>{y + 1}</td>
                                          <td>{x.DocumentType}</td>
                                          <td><a href={`${serverLink}public/uploads/application/document/${x.FileName}`}>View Document</a></td>
                                          <td>{formatDateAndTime(x.InsertedOn, "date")}</td>
                                        </tr>
                                      )
                                    })
                                  }
                                </tbody>
                                :
                                <tbody>
                                  <tr>
                                    <td><label className="alert alert-lg alert-warning">NO DOCUMENTS UPLOADED</label></td>
                                  </tr>
                                </tbody>
                            }
                          </table></>
                      }

                      {
                        AppDocuments.length > 0 && app.applicantionid !== "" &&

                        <div className="row col-md-12 mt-3">
                          <h4>Add Documents</h4>
                          <div className="col-md-4">
                            <input type={"file"} className="form-control" onChange={(e) => {
                              setApp({
                                ...app,
                                File: e.target.files[0]
                              })
                            }} />
                          </div>
                          <div className="col-md-4">
                            <select className="form-control" onChange={(e) => {
                              setApp({
                                ...app,
                                DocumentName: e.target.value
                              })
                            }} >
                              <option value={""}>Select document type</option>
                              {
                                documentList.length > 0 &&
                                documentList.map((x, y) => {
                                  return (
                                    <option key={y} value={x.DocumentName}>{x.DocumentName}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                          <div className="col-md-4">
                            <button onClick={UploadFile} className="btn btn-sm btn-primary" >Upload</button>
                          </div>

                        </div>
                      }
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationDashboard;
