import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import Loader from "../../common/loader/loader";
import PageHeader from "../../common/pageheader/pageheader";
import axios from "axios";
import {
    projectAddress,
    projectEmail,
    projectName,
    projectPhone,
    serverLink
} from "../../../resources/url";
import Select2 from "react-select2-wrapper";
import { useQRCode } from 'next-qrcode';
import {useReactToPrint} from "react-to-print";
import {toast} from "react-toastify";
import classes from './id-card.css';
import Barcode from "react-barcode/lib/react-barcode";
import {Link} from "react-router-dom";
const JsBarcode = require('jsbarcode');

function GenerateIDCard() {
    const [isLoading, setIsLoading] = useState(false);
    const { Image } = useQRCode();
    const componentRef = useRef();
    const [data, setData] = useState({
        students: [],
        staff: [],
        biometric: [],
        designation: [],
        statics: [],
        courses: [],
    });
    const [studentsList, setStudentsList] = useState([]);
    const [staffList, setStaffList] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState({
        studentInfo: [],
        studentBiometric: [],
        signature: "",
        logo: "",
    })
    const [selectedStaff, setSelectedStaff] = useState({
        staffInfo: [],
        staffBiometric: [],
        signature: "",
        logo: "",
    })
    const [contentToRender, setContentToRender] = useState(false)
    const [renderIDCard, setRenderIDCard] = useState(false)
    const [canPrintIDCard, setCanPrintIDCard] = useState(false)
    const [createRequest, setCreateRequest] = useState({
        UserID: "",
        CardType: "",
    });

    const onEdit = (e) => {
        setCreateRequest({
            ...createRequest,
            [e.target.id]: e.target.value,
        });

        if (e.target.value === "Staff"){
            setStudentsList([]);
             axios.get(`${serverLink}staff/biometric-devices/id/card/data`)
                .then((res) => {
                    setData(res.data)
                    if (res.data.staff.length > 0 ){
                        let rows = [];
                        res.data.staff.map(item => {
                            rows.push({
                                id: item.StaffID,
                                text: `${item.FirstName} ${item.MiddleName} ${item.Surname} (${item.StaffID})`
                            })
                        })
                        setStaffList(rows);
                        setContentToRender(true);
                    }
                })
                .catch((err) => {
                    console.log("NETWORK ERROR");
                });
        }

        if (e.target.value === "Student"){
            setStaffList([]);
            axios.get(`${serverLink}staff/biometric-devices/id/card/data`)
                .then((res) => {
                    setData(res.data)
                    if (res.data.students.length > 0 ){
                        let rows = [];
                        res.data.students.map(item => {
                            rows.push({
                                id: item.StudentID,
                                text: `${item.FirstName} ${item.MiddleName} ${item.Surname} (${item.StudentID})`
                            })
                        })
                        setStudentsList(rows);
                        setContentToRender(true);
                    }
                })
                .catch((err) => {
                    console.log("NETWORK ERROR");
                });
        }

        setRenderIDCard(false);
    }

    const onSearch = async () => {
        if (createRequest.CardType === "Staff") {
            if (createRequest.UserID !== ""){
                const filteredStaff = data.staff.filter(i => i.StaffID === createRequest.UserID);
                const staffBiometric = data.biometric.filter(i => i.UserID === createRequest.UserID);
                const signature = data.statics.filter(i => i.OwnerID === "E0001");
                const logo = data.statics.filter(i => i.ContentType === "logo");
                setSelectedStaff({
                    staffInfo: filteredStaff,
                    staffBiometric: staffBiometric,
                    signature: signature,
                    logo: logo,
                })
                setRenderIDCard(true)
                setCanPrintIDCard(true)
                setSelectedStudent([])
            }
        }

        if (createRequest.CardType === "Student") {
                if (createRequest.UserID !== ""){
                    const filteredStudent = data.students.filter(i => i.StudentID === createRequest.UserID);
                    const studentBiometric = data.biometric.filter(i => i.UserID === createRequest.UserID);
                    const signature = data.statics.filter(i => i.OwnerID === "E0001");
                    const logo = data.statics.filter(i => i.ContentType === "logo");

                    setSelectedStudent({
                        studentInfo: filteredStudent,
                        studentBiometric: studentBiometric,
                        signature: signature,
                        logo: logo,
                    })
                    setRenderIDCard(true)
                    setCanPrintIDCard(true)
                    setSelectedStaff([])
                }
            }
    }

    const printIDCard = useReactToPrint({
        content: () => componentRef.current,
    })

    useEffect( () => {
        // getData().then();
    }, [])

    return isLoading ? <Loader/> : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Generate Staff and Students Identity Card"}
                items={["User", "Biometric & Devices", "Generate ID Card"]}
            />

            <div className="flex-column-fluid h-500px">
                <div className="card">
                    <div className="card-body pt-0 h-500px">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-3">

                                    <div className="form-group pt-3">
                                            <label htmlFor="CardType">Select Card Type</label>
                                            <select
                                                className="form-control"
                                                id="CardType"
                                                name="CardType"
                                                value={createRequest.CardType}
                                                onChange={onEdit}
                                            >
                                                <option value="">Select Option</option>
                                                <option value="Staff">Staff</option>
                                                <option value="Student">Student</option>
                                            </select>
                                    </div>

                                    {contentToRender === true && createRequest.CardType === "Staff" && (
                                        <>
                                            {studentsList.length < 1 &&
                                            <>
                                                <div className="form-group pt-3">
                                                    <label htmlFor="UserID">Select Staff</label>
                                                    <Select2
                                                        id="UserID"
                                                        name="UserID"
                                                        data={staffList}
                                                        value={createRequest.UserID}
                                                        className={"form-control"}
                                                        onSelect={onEdit}
                                                        options={{
                                                            placeholder: "Search Staff",
                                                        }}
                                                    />
                                                </div >
                                            </>
                                            }
                                        </>
                                    )}

                                    {contentToRender === true && createRequest.CardType === "Student" &&  (
                                        <>
                                        {staffList.length < 1 && (
                                            <>

                                                <div className="form-group pt-3">
                                                    <label htmlFor="UserID">Select Student</label>
                                                    <Select2
                                                        id="UserID"
                                                        name="UserID"
                                                        data={studentsList}
                                                        value={createRequest.UserID}
                                                        className={"form-control"}
                                                        onSelect={onEdit}
                                                        options={{
                                                            placeholder: "Search Student",
                                                        }}
                                                    />

                                                </div>

                                            </>
                                        )}
                                        </>

                                    )}

                                    <div className="pt-3"> <button className="btn btn-primary w-100" onClick={onSearch}>Generate</button></div>

                                    {renderIDCard && createRequest.CardType === "Staff" && (
                                        <>
                                            {selectedStaff.staffInfo !== "undefined" && selectedStaff.staffInfo !== "" &&
                                                selectedStaff.staffBiometric !== "undefined" && selectedStaff.staffBiometric !== ""
                                                && selectedStaff.staffInfo.length > 0 && selectedStaff.staffBiometric.length > 0 &&
                                                canPrintIDCard === true && (
                                                    <div className="pt-3"> <button className="btn btn-primary w-100" onClick={printIDCard}>Print</button></div>
                                                )}

                                        </>
                                    )}


                                    {renderIDCard && createRequest.CardType === "Student" && (
                                        <>
                                            {selectedStudent.studentInfo !== "undefined" && selectedStudent.studentInfo !== "" &&
                                                selectedStudent.studentBiometric !== "undefined" && selectedStudent.studentBiometric !== ""
                                                && selectedStudent.studentInfo.length > 0 && selectedStudent.studentBiometric.length >  0 &&
                                                canPrintIDCard === true && (
                                                    <div className="pt-3"> <button className="btn btn-primary w-100" onClick={printIDCard}>Print</button></div>
                                                )}

                                        </>
                                    )}
                                    <br />
                                </div>

                                {renderIDCard && createRequest.CardType === "Staff" && (
                                    <>
                                        {selectedStaff.staffInfo !== "undefined" && selectedStaff.staffInfo !== "" &&
                                        selectedStaff.staffBiometric !== "undefined" && selectedStaff.staffBiometric !== ""
                                        && selectedStaff.staffInfo.length > 0 && selectedStaff.staffBiometric.length > 0 ? (
                                            <>
                                                <div className="content-holder col-md-3 pt-5" ref={componentRef}>
                                                    {/*FRONT PAGE*/}
                                                    <div className="padding">
                                                        <div className="front">
                                                            <div className="top">
                                                                <img
                                                                    src={
                                                                        selectedStaff.staffBiometric.length > 0
                                                                            ? `${serverLink}public/uploads/biometric/${selectedStaff.staffBiometric[0].Passport}`
                                                                            : "https://via.placeholder.com/150"
                                                                    }
                                                                    alt="Staff Picture"
                                                                />
                                                                {/*<img src="https://via.placeholder.com/110x110" />*/}
                                                            </div>
                                                            <div className="bottom">
                                                                <p>
                                                                    {selectedStaff.staffInfo[0].FirstName}{" "}
                                                                    {selectedStaff.staffInfo[0].MiddleName}{" "}
                                                                    {selectedStaff.staffInfo[0].Surname} < br/>
                                                                    <h6>({selectedStaff.staffInfo[0].StaffID})</h6>
                                                                </p>
                                                                <p className="desi">
                                                                    {data.designation.length > 0 &&
                                                                        data.designation
                                                                            .filter(
                                                                                (i) =>
                                                                                    i.EntryID === selectedStaff.staffInfo[0].DesignationID
                                                                            ).map(
                                                                            (r) => r.DesignationName
                                                                        )
                                                                    }
                                                                </p>

                                                                <div className="barcode">
                                                                    <Image
                                                                        text={selectedStaff.staffInfo[0].StaffID}
                                                                        options={{
                                                                            type: 'image/jpeg',
                                                                            quality: 0.3,
                                                                            level: 'M',
                                                                            margin: 3,
                                                                            scale: 4,
                                                                            width: 200,
                                                                            color: {
                                                                                dark: '#010599FF',
                                                                                light: '#FFBF60FF',
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>

                                                                {/*<h5 className="expiry-date pt-5">Valid Until: </h5>*/}
                                                                <div className="staff-type p-3">
                                                                    {createRequest.CardType}{" "} ID Card
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="pagebreak"></div>

                                                    {/*BACK PAGE*/}
                                                    <div className="padding">
                                                        <div className="front">
                                                            <div className="back-university-name">
                                                                <h1 className="Details">
                                                                    {projectName}
                                                                    {" "}
                                                                    <img style={{
                                                                        width: "30px",
                                                                        height: "30px",
                                                                    }}
                                                                         src={
                                                                             selectedStaff.logo.length > 0
                                                                                 ? `${serverLink}public/uploads/biometric/${selectedStaff.logo[0].Content}`
                                                                                 : "https://via.placeholder.com/150"
                                                                         }
                                                                         alt="Staff Picture"
                                                                    />
                                                                </h1>
                                                            </div>
                                                            <div className="hr">
                                                                <div className="details-info" >
                                                                    <p><b>Registrar's Signature</b></p>
                                                                    <div style={{
                                                                        textAlign:'center'
                                                                    }}>
                                                                        <img style={{
                                                                            width: "70px",
                                                                            height: "70px",
                                                                        }}
                                                                             src={
                                                                                 selectedStaff.signature.length > 0
                                                                                     ? `${serverLink}public/uploads/biometric/${selectedStaff.signature[0].Content}`
                                                                                     : "https://via.placeholder.com/150"
                                                                             }
                                                                             alt="Staff Picture"
                                                                        />
                                                                        <p className="back-text">{projectAddress}</p>
                                                                        <p className="back-text">Email:{" "}{projectEmail}</p>
                                                                        <p className="back-text">Tel:{" "}{projectPhone}</p>


                                                                    </div>
                                                                    <div className="staff-type-back p-3">
                                                                        {createRequest.CardType}{" "} ID Card
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        ):(
                                            <div className="alert alert-info">
                                                Selected staff biometric and passport is empty, kindly
                                                {" "}
                                                <Link to="/users/capture/biometric">
                                                    click here to capture
                                                </Link>

                                            </div>
                                        )}
                                    </>
                                )}

                                {renderIDCard && createRequest.CardType === "Student" && (
                                    <>
                                        {selectedStudent.studentInfo !== "undefined" && selectedStudent.studentInfo !== "" &&
                                        selectedStudent.studentBiometric !== "undefined" && selectedStudent.studentBiometric !== ""
                                        && selectedStudent.studentInfo.length > 0 && selectedStudent.studentBiometric.length > 0 ? (
                                            <>
                                                <div className="content-holder col-md-3 pt-5" ref={componentRef}>
                                                    {/*FRONT PAGE*/}
                                                    <div className="padding">
                                                        <div className="front">
                                                            <div className="top">
                                                                <img
                                                                    src={
                                                                        selectedStudent.studentBiometric.length > 0
                                                                            ? `${serverLink}public/uploads/biometric/${selectedStudent.studentBiometric[0].Passport}`
                                                                            : "https://via.placeholder.com/150"
                                                                    }
                                                                    alt="Staff Picture"
                                                                />
                                                                {/*<img src="https://via.placeholder.com/110x110" />*/}
                                                            </div>
                                                            <div className="bottom">
                                                                <p>
                                                                    {selectedStudent.studentInfo[0].FirstName}{" "}
                                                                    {selectedStudent.studentInfo[0].MiddleName}{" "}
                                                                    {selectedStudent.studentInfo[0].Surname} < br/>
                                                                    <h6>({selectedStudent.studentInfo[0].StudentID})</h6>
                                                                </p>
                                                                <p className="desi">
                                                                    {data.courses.length > 0 &&
                                                                        data.courses
                                                                            .filter(
                                                                                (i) =>
                                                                                    i.CourseCode === selectedStudent.studentInfo[0].CourseCode
                                                                            ).map(
                                                                            (r) => r.CourseName
                                                                        )
                                                                    }
                                                                </p>

                                                                <div className="barcode">
                                                                    <Image
                                                                        text={selectedStudent.studentInfo[0].StudentID}
                                                                        options={{
                                                                            type: 'image/jpeg',
                                                                            quality: 0.3,
                                                                            level: 'M',
                                                                            margin: 3,
                                                                            scale: 4,
                                                                            width: 200,
                                                                            color: {
                                                                                dark: '#010599FF',
                                                                                light: '#FFBF60FF',
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>

                                                                {/*<h5 className="expiry-date pt-5">Valid Until: </h5>*/}
                                                                <div className="staff-type p-3">
                                                                    {createRequest.CardType}{" "} ID Card
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="pagebreak"></div>

                                                    {/*BACK PAGE*/}
                                                    <div className="padding">
                                                        <div className="front">
                                                            <div className="back-university-name">
                                                                <h1 className="Details">
                                                                    {projectName}
                                                                    {" "}
                                                                    <img style={{
                                                                        width: "30px",
                                                                        height: "30px",
                                                                    }}
                                                                         src={
                                                                             selectedStudent.logo.length > 0
                                                                                 ? `${serverLink}public/uploads/biometric/${selectedStudent.logo[0].Content}`
                                                                                 : "https://via.placeholder.com/150"
                                                                         }
                                                                         alt="Staff Picture"
                                                                    />
                                                                </h1>
                                                            </div>
                                                            <div className="hr">
                                                                <div className="details-info" >
                                                                    <p><b>Registrar's Signature</b></p>
                                                                    <div style={{
                                                                        textAlign:'center'
                                                                    }}>
                                                                        <img style={{
                                                                            width: "70px",
                                                                            height: "70px",
                                                                        }}
                                                                             src={
                                                                                 selectedStudent.signature.length > 0
                                                                                     ? `${serverLink}public/uploads/biometric/${selectedStudent.signature[0].Content}`
                                                                                     : "https://via.placeholder.com/150"
                                                                             }
                                                                             alt="Staff Picture"
                                                                        />
                                                                        <p className="back-text">{projectAddress}</p>
                                                                        <p className="back-text">Email:{" "}{projectEmail}</p>
                                                                        <p className="back-text">Tel:{" "}{projectPhone}</p>


                                                                    </div>
                                                                    <div className="staff-type-back p-3">
                                                                        {createRequest.CardType}{" "} ID Card
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ):(
                                            <div className="alert alert-info">
                                                Selected student biometric and passport is empty, kindly click here to capture.
                                                {" "}
                                                <Link to="/users/capture/biometric">
                                                    click here to capture
                                                </Link>
                                            </div>
                                        )}
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(GenerateIDCard);
