import React, { useEffect, useState } from "react";
import Modal from "../../common/modal/modal";
import PageHeader from "../../common/pageheader/pageheader";
import Table from "../../common/table/table";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import Loader from "../../common/loader/loader";
import ReportTable from "../../common/table/report_table";
import { formatDateAndTime } from "../../../resources/constants";


function ChangeofCourseReport(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setisFormLoading] = useState('off')
    const columns =["sn", "StudentID", "Student Name", "Level", "Current Course ", "Requested Course", "Semester", "Guardian", "Stage",  "Approved By", "status", "Approval date",]
    const [tableData, setTableData] = useState([]);

    const getChangeofCourse = async () => {
        await axios.get(`${serverLink}staff/registration/change-of-course/applications/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    let rows = [];
                    result.data.map((item, index) => {
                        const status = item.RegistrarApprovedBy !== null ? item.RegistrarApprovedBy.split(",")[0] : "yay";
                        const RegistrarApprovedBy =  item.RegistrarApprovedBy !== null ? item.RegistrarApprovedBy.split(",")[1] : "";
                        let stage = "";
                        if(item.GuardianApprovedBy === null){
                            stage = "Pending Guardian Approval";
                        }
                        if(item.GuardianApprovedBy !== null){
                            stage = "Guardian Approved";
                        }
                        if(item.AdmissionApprovedBy !== null){
                            stage = "Admission Officer Approved";
                        }
                        if(item.RegistrarApprovedBy !== null){
                            stage = "Registrar Approved";
                        }
                        rows.push([
                            index + 1,
                            item.StudentID,
                            item.StudentName,
                            item.StudentLevel+" Level",
                            item.CourseName,
                            item.RequestedCourseName,
                            item.SemesterCode,
                            item.GuardianName+ "\n\n"+item.GuardianEmailAddress + "\n\n"+item.GuardianPhone,
                            stage,
                            RegistrarApprovedBy,
                            <span className={status === "1" ? "badge badge-success": status === "2" ? "badge badge-danger": "badge badge-secondary"}>
                                {status === "1" ? "Approved":status === "2" ? "Denied": "Pending"}
                            </span> ,
                            formatDateAndTime(item.RegistrarApprovedDate, "date"),
                                ]);
                    });

                    setTableData(rows);
                }

                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    useEffect(() => {
        getChangeofCourse();
    }, []);

    return isLoading ? (
        <Loader />
    ) : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Change of course"}
                items={["Registration", "Change of Course", "Report"]}
            />
            <div className="flex-column-fluid">
                <div className="card">
                    <div className="card-body pt-0">
                        <div className="col-md-12" style={{ overflowX: 'auto' }}>
                        <ReportTable columns={columns} data={tableData} title={"Guardian Approval"} />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        LoginDetails: state.LoginDetails,
        FacultyList: state.FacultyList
    };
};

export default  ChangeofCourseReport; //connect(mapStateToProps, null)(ChangeofCourseReport);
