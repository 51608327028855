import React, { useEffect, useState } from "react";
import Modal from "../../common/modal/modal";
import PageHeader from "../../common/pageheader/pageheader";
import Table from "../../common/table/table";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import Loader from "../../common/loader/loader";
import { showAlert } from "../../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import { connect } from "react-redux/es/exports";
import { ModulesForm } from "./modulesform";
import ModulePrerequisites from "./prerequisites";
import ModuleTemplate from "../../../images/Module Templates.csv"


function Modules(props) {
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setisFormLoading] = useState('off')
    const [datatable, setDatatable] = useState({
        columns: [
            {
                label: "S/N",
                field: "sn",
            },
            {
                label: "Module Code",
                field: "ModuleCode",
            },
            {
                label: "Module Name",
                field: "ModuleName",
            },
            {
                label: "ModuleType",
                field: "ModuleType",
            },
            {
                label: "CreditUnit",
                field: "CreditUnit",
            },
            {
                label: "CAPerCon",
                field: "CAPerCon",
            },
            {
                label: "ExamPerCon",
                field: "ExamPerCon",
            },
            {
                label: "Action",
                field: "action",
            },
        ],
        rows: [],
    });

    const [departmentsList, setDepartments] = useState(props.DepartmentList)

    const getDepartments = async () => {
        await axios
            .get(`${serverLink}staff/academics/course/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setDepartments(result.data)
                }
            })
    }

    const [upload, setUpload] = useState({
        file: "",
        InsertedBy: props.LoginDetails[0].StaffID,
    });

    const [createModule, setcreateModule] = useState({
        EntryID: "",
        ModuleCode: "",
        ModuleName: "",
        ModuleType: "",
        CreditUnit: "",
        CAPerCon: "",
        ExamPerCon: "",
        DepartmentCode:"",
        InsertedBy: props.LoginDetails[0].StaffID,
        UpdateDate: ""
    });

    const [modulesList, setModulesList]= useState([])
    const getModules = async () => {
        getDepartments();
        await axios
            .get(`${serverLink}staff/academics/modules/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setModulesList(result.data)
                    let rows = [];
                    result.data.map((modules, index) => {
                        rows.push({
                            sn: index + 1,
                            EntryID: modules.EntryID,
                            ModuleCode: modules.ModuleCode,
                            ModuleName: modules.ModuleName,
                            ModuleType: modules.ModuleType,
                            DepartmentCode: modules.CourseCode,
                            CreditUnit: modules.CreditUnit,
                            CAPerCon: modules.CAPerCon,
                            ExamPerCon: modules.ExamPerCon,
                            action: (
                                <button
                                    className="btn btn-sm btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_general"
                                    onClick={() => {
                                        setcreateModule({
                                            ...createModule,
                                            EntryID: modules.EntryID,
                                            ModuleCode: modules.ModuleCode,
                                            ModuleName: modules.ModuleName,
                                            ModuleType: modules.ModuleType,
                                            DepartmentCode: modules.DepartmentCode,
                                            CreditUnit: modules.CreditUnit,
                                            CAPerCon: modules.CAPerCon,
                                            ExamPerCon: modules.ExamPerCon,
                                            UpdatedBy: props.LoginDetails[0].StaffID,
                                            action: "update"
                                        });
                                    }
                                    }
                                >
                                    <i className="fa fa-pen" />
                                </button>
                            ),
                        });
                    });

                    setDatatable({
                        ...datatable,
                        columns: datatable.columns,
                        rows: rows,
                    });
                }

                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const onEdit = (e) => {
        setcreateModule({
            ...createModule,
            [e.target.id]: e.target.value
        });
    };

    const onEditFile = (e) => {
        const id = e.target.id;
        const value = id === "file" ? e.target.files[0] : e.target.value;
        setUpload({
            ...upload,
            [id]: value,
        });
    };


    const onUploadFile = async () => {
        await axios
            .get(`${serverLink}staff/academics/modules/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    let existing_modules = [];
                    result.data.map((modules, index) => {
                        existing_modules.push(modules.ModuleCode)
                    })

                    const formData = new FormData();
                    formData.append('file', upload.file);
                    formData.append('modules', existing_modules);
                    formData.append('InsertedBy', upload.InsertedBy);
                     axios.post(`${serverLink}staff/academics/modules/bulk`, formData)
                        .then((result) => {
                            let duplicate = result.data.duplicate;
                            let contribution = result.data.contribution;
                            if (result.data.message === "success") {
                                document.getElementById("closeModal").click()
                                toast.success("Modules Uploaded Successfully");
                                showAlert("MODULE UPLOADED", `Modules Uploaded Successfully! \n   ${duplicate.length > 0 ? ` However, the following modules exist in the database: ${duplicate}` : ""} \n  ${contribution.length > 0 ? `Contribution Limit Exceeded 100% for: ${contribution}` : ""} `, "success");
                                getModules();
                                setisFormLoading('off')
                            } else if (result.data.message === "all exist") {
                                showAlert("MODULE EXIST", `All the Uploaded modules exist in the database, Please check and try again! \n ${duplicate.length > 0 ? duplicate : ""}  \n  ${contribution.length > 0 ? `Contribution Limit Exceeded 100% for: ${contribution}` : ""} `, "error");
                            } else if (result.data.message === "limit exceeded"){
                                showAlert("CONTRIBUTION LIMIT EXCEEDED", `All the Uploaded modules have exceeded contribution limit, Please check and try again! \n  ${contribution.length > 0 ? contribution : ""}`, "error");
                            } else {
                                showAlert(
                                    "ERROR",
                                    "Something went wrong. Please try again!",
                                    "error"
                                );
                            }
                        })
                        .catch((error) => {
                            showAlert(
                                "NETWORK ERROR",
                                "Please check your connection and try again!",
                                "error"
                            );
                        });
                }
            })

    }


    const onSubmit = async (e) => {
        
        e.preventDefault();
        if (createModule.EntryID === "") {
            if(parseInt(createModule.CAPerCon) + parseInt(createModule.ExamPerCon) > 100){
                showAlert("CONTRIBUTION LIMIT EXCEEDED", "CA and Exam contribution cannot exceed 100%!", "error");
            }
            else{
                setisFormLoading('on')
            await axios
                .post(`${serverLink}staff/academics/modules/add`, createModule)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("modules Added Successfully");
                        getModules();
                        setcreateModule({
                            ...createModule,
                            EntryID: "",
                            ModuleCode: "",
                            ModuleName: "",
                            ModuleType: "",
                            CreditUnit: "",
                            CAPerCon: "",
                            ExamPerCon: "",
                        });
                        setisFormLoading('off')
                        document.getElementById("closeModal").click();
                    } else if (result.data.message === "exist") {
                        showAlert("MODULE EXIST", "Module already exist!", "error");
                    } else {
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
            }
        } else {
            if(parseInt(createModule.CAPerCon) + parseInt(createModule.ExamPerCon) > 100){
                showAlert("CONTRIBUTION LIMIT EXCEEDED", "CA and Exam contribution cannot exceed 100%!", "error");
            }
            else{
                setisFormLoading('on')
                await axios
                    .patch(`${serverLink}staff/academics/modules/update`, createModule)
                    .then((result) => {
                        if (result.data.message === "success") {
                            toast.success("modules Updated Successfully");
                            getModules();
                            setcreateModule({
                                ...createModule,
                                EntryID: "",
                                ModuleCode: "",
                                ModuleName: "",
                                ModuleType: "",
                                CreditUnit: "",
                                CAPerCon: "",
                                ExamPerCon: "",
                            });
                            setisFormLoading('off')
                            document.getElementById("closeModal").click();
                        } else {
                            showAlert(
                                "ERROR",
                                "Something went wrong. Please try again!",
                                "error"
                            );
                        }
                    })
                    .catch((error) => {
                        showAlert(
                            "NETWORK ERROR",
                            "Please check your connection and try again!",
                            "error"
                        );
                    });
            }
           
        }
    };

    useEffect(() => {
        getModules();
    }, []);

    return isLoading ? (
        <Loader />
    ) : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Modules"}
                items={["Academics", "Modules", "Manage modules"]}
            />

                <div id="kt_content_container" className="container-custom container-xxl d-flex flex-column-fluid">

                    <div className="pt-0 pb-0">
                        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                            <li className="nav-item mt-2">
                                <a className="nav-link text-active-primary ms-0 me-10 py-5 active" data-bs-toggle="tab" href="#kt_tabs_tab_1">Modules</a>
                            </li>
                            <li className="nav-item mt-2">
                                <a className="nav-link text-active-primary ms-0 me-10 py-5" data-bs-toggle="tab" href="#kt_tabs_tab_2">Prerequisites</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="flex-column-fluid">
                    <div
                        className="tab-content"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: true, lg: false}"
                        data-kt-scroll-height="auto"
                        data-kt-scroll-offset="70px"
                    >
                        <div
                            className="tab-pane fade active show"
                            id="kt_tabs_tab_1"
                        >
                            <div className="flex-column-fluid">
                                <div className="card">
                                    <div className="card-header border-0 pt-6">
                                        <div className="card-title" />
                                        <div className="card-toolbar">
                                            <div
                                                className="d-flex justify-content-end"
                                                data-kt-customer-table-toolbar="base"
                                            >
                                                <button
                                                    type="button"
                                                    className="btn btn-primary mr-4"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#bulk_modal"
                                                    style={{marginRight: '20px'}}
                                                    >
                                                    Bulk Upload
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#kt_modal_general"
                                                    onClick={() =>
                                                        setcreateModule({
                                                            ...createModule,
                                                            EntryID: "",
                                                            ModuleCode: "",
                                                            ModuleName: "",
                                                            ModuleType: "",
                                                            CreditUnit: "",
                                                            CAPerCon: "",
                                                            ExamPerCon: "",
                                                            InsertedBy: props.LoginDetails[0].StaffID,
                                                        })
                                                    }
                                                >
                                                    Add modules
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="col-md-12" style={{ overflowX: 'auto' }}>
                                            <Table data={datatable} />
                                        </div>
                                    </div>
                                </div>
                                <Modal title={"modules Form"}>
                                    <ModulesForm
                                        onEdit={onEdit}
                                        createModule={createModule}
                                        onSubmit={onSubmit}
                                        departmentsList={departmentsList}
                                        isFormLoading={isFormLoading}
                                    />

                                </Modal>

                                <Modal title={"Modules Bulk Upload"} id={"bulk_modal"}>
                                    <div className="row">
                                        <div className="form-group">
                                            <a href={ModuleTemplate} className="float-end" >
                                                Click here to download the bulk upload template
                                            </a>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="Designation">File </label>
                                            <input
                                                type="file"
                                                accept=".csv"
                                                id="file"
                                                name="file"
                                                className="form-control"
                                                placeholder="File Name"
                                                required
                                                onChange={onEditFile}
                                            />
                                            <span className="badge bg-danger mt-1">
                                                Only .csv is allowed
                                              </span>
                                        </div>
                                        <div className="form-group pt-4">
                                            <button type="submit" className="btn btn-primary w-100" id="kt_modal_new_address_submit" onClick={onUploadFile} data-kt-indicator={props.isFormLoading}>
                                                <span className="indicator-label">Upload</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </Modal>

                            </div>

                        </div>
                        <div
                            className="tab-pane fade"
                            id="kt_tabs_tab_2">
                            <ModulePrerequisites 
                                InsertedBy={props.LoginDetails[0].StaffID}
                                modulesList={modulesList}
                            />
                        </div>
                    </div>
                </div>
           
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        LoginDetails: state.LoginDetails,
        DepartmentList: state.DepartmentList
    };
};
export default connect(mapStateToProps, null)(Modules);
