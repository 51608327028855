import React, { useEffect, useState } from "react";
import Modal from "../../common/modal/modal";
import PageHeader from "../../common/pageheader/pageheader";
import Table from "../../common/table/table";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import Loader from "../../common/loader/loader";
import { showAlert } from "../../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import { connect } from "react-redux/es/exports";


function Department(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setisFormLoading] = useState('off')
    const [datatable, setDatatable] = useState({
        columns: [
            {
                label: "S/N",
                field: "sn",
            },
            {
                label: "Department Code",
                field: "DepartmentCode",
            },
            {
                label: "Department Name",
                field: "DepartmentName",
            },
            {
                label: "Faculty",
                field: "FacultyCode",
            },
            {
                label: "Is Academic",
                field: "IsAcademic",
            },
            {
                label: "IsAwardDegree",
                field: "IsAwardDegree",
            },
            {
                label: "HOD",
                field: "DepartmentHead",
            },
            {
                label: "Action",
                field: "action",
            },
        ],
        rows: [],
    });

    const [createDepartment, setCreateDepartment] = useState({
        EntryID: "",
        DepartmentCode: "",
        DepartmentName: "",
        FacultyCode: "",
        IsAwardDegree: "",
        IsAcademic: "",
        DepartmentHead: "",
        InsertedBy: props.LoginDetails[0].StaffID,
    });

    const [facultyList, setFacultyList] = useState(props.FacultyList)
    const [academicStaff, setAcademicStaff] = useState([])

    const getAcademicStaff = async () => {
        await axios
            .get(`${serverLink}staff/academics/department/academic-staff/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setAcademicStaff(result.data)
                }
            })
    }

    const getDepartments = async () => {
        getAcademicStaff(); 
        await axios
            .get(`${serverLink}staff/academics/department/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    let rows = [];
                    result.data.map((department, index) => {
                        rows.push({
                            sn: index + 1,
                            EntryID: department.EntryID,
                            DepartmentCode: department.DepartmentCode,
                            DepartmentName: department.DepartmentName,
                            FacultyCode: props.FacultyList.filter(x => x.FacultyCode === department.FacultyCode)[0].FacultyName,
                            IsAwardDegree: department.IsAwardDegree === 1 ? "YES" : "NO",
                            IsAcademic: department.IsAcademic === 1 ? "YES" : "NO",
                            DepartmentHead: department.DepartmentHead,
                            action: (
                                <button
                                    className="btn btn-sm btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_general"
                                    onClick={() => {
                                        setCreateDepartment({
                                            EntryID: department.EntryID,
                                            DepartmentCode: department.DepartmentCode,
                                            DepartmentName: department.DepartmentName,
                                            FacultyCode: department.FacultyCode,
                                            IsAwardDegree: department.IsAwardDegree,
                                            IsAcademic: department.IsAcademic,
                                            DepartmentHead: department.DepartmentHead,
                                            UpdatedBy: props.LoginDetails[0].StaffID
                                        });
                                    }
                                    }
                                >
                                    <i className="fa fa-pen" />
                                </button>
                            ),
                        });
                    });

                    setDatatable({
                        ...datatable,
                        columns: datatable.columns,
                        rows: rows,
                    });
                }

                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const onEdit = (e) => {
        setCreateDepartment({
            ...createDepartment,
            [e.target.id]: e.target.value,
        });
    };

    const onSubmit = async () => {
        if (createDepartment.DepartmentName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter the department name", "error");
            return false;
        }
        if (createDepartment.DepartmentCode.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter the department code", "error");
            return false;
        }
        if (createDepartment.FacultyCode.trim() === "") {
            showAlert("EMPTY FIELD", "Please select the faculty", "error");
            return false;
        }
        if (createDepartment.IsAcademic.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select the Academic type", "error");
            return false;
        }
        if (createDepartment.IsAwardDegree.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select the award type", "error");
            return false;
        }
        if (createDepartment.DepartmentHead.trim() === "") {
            showAlert("EMPTY FIELD", "Please select the Head of Department", "error");
            return false;
        }

        if (createDepartment.EntryID === "") {
            setisFormLoading('on')
            await axios
                .post(`${serverLink}staff/academics/department/add`, createDepartment)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Department Added Successfully");
                        getDepartments();
                        setCreateDepartment({
                            ...createDepartment,
                            EntryID: "",
                            DepartmentCode: "",
                            DepartmentName: "",
                            FacultyCode: "",
                            IsAwardDegree: "",
                            IsAcademic: "",
                            DepartmentHead: "",
                        });
                        setisFormLoading('off')
                        document.getElementById("closeModal").click();
                    } else if (result.data.message === "exist") {
                        showAlert("DEPARTMENT EXIST", "Department already exist!", "error");
                    } else {
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        } else {
            setisFormLoading('on')
            await axios
                .patch(`${serverLink}staff/academics/department/update`, createDepartment)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Department Updated Successfully");
                        getDepartments();
                        setCreateDepartment({
                            ...createDepartment,
                            EntryID: "",
                            DepartmentCode: "",
                            DepartmentName: "",
                            FacultyCode: "",
                            IsAwardDegree: "",
                            IsAcademic: "",
                            DepartmentHead: "",
                        });
                        setisFormLoading('off')
                        document.getElementById("closeModal").click();
                    } else {
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }
    };


    useEffect(() => {
        getDepartments();
    }, []);

    return isLoading ? (
        <Loader />
    ) : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Department"}
                items={["Academics", "Department", "Manage department"]}
            />
            <div className="flex-column-fluid">
                <div className="card">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title" />
                        <div className="card-toolbar">
                            <div
                                className="d-flex justify-content-end"
                                data-kt-customer-table-toolbar="base"
                            >
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_general"
                                    onClick={() =>
                                        setCreateDepartment({
                                            ...createDepartment,
                                            EntryID: "",
                                            DepartmentCode: "",
                                            DepartmentName: "",
                                            FacultyCode: "",
                                            IsAwardDegree: "",
                                            IsAcademic: "",
                                            DepartmentHead: "",
                                            InsertedBy: props.LoginDetails[0].StaffID
                                        })
                                    }
                                >
                                    Add Department
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="col-md-12" style={{ overflowX: 'auto' }}>
                            <Table data={datatable} />
                        </div>
                    </div>
                </div>
                <Modal title={"Department Form"}>
                    <div className="row">
                        <div className="form-group">
                            <label htmlFor="FacultyCode">Faculty</label>
                            <select id="FacultyCode" onChange={onEdit}
                                value={createDepartment.FacultyCode}
                                className="form-select form-select-solid"
                                data-kt-select2="true"
                                data-placeholder="Select option"
                                data-dropdown-parent="#kt_menu_624456606a84b" data-allow-clear="true">
                                <option value={""}>-select faculty-</option>
                                {props.FacultyList.length > 0 ?
                                    <>
                                        {props.FacultyList.map((x, y) => {
                                            return (
                                                <option key={y} value={x.FacultyCode}>{x.FacultyName}</option>
                                            )
                                        })}
                                    </>
                                    :
                                    <></>}
                            </select>
                        </div>

                        <div className="form-group mt-4">
                            <label htmlFor="DepartmentName">Department Name</label>
                            <input
                                type="text"
                                id={"DepartmentName"}
                                onChange={onEdit}
                                value={createDepartment.DepartmentName}
                                className={"form-control"}
                                placeholder={"Enter the Department Name"}
                            />
                        </div>

                        <div className="form-group mt-4">
                            <label htmlFor="DepartmentCode">Department Code</label>
                            <input
                                type="text"
                                id={"DepartmentCode"}
                                onChange={onEdit}
                                value={createDepartment.DepartmentCode}
                                className={"form-control"}
                                style={{textTransform:'uppercase'}} 
                                placeholder={"Enter the Department Code"}
                            />
                        </div>

                        <div className="col-md-6">
                            <div className="form-group mt-4">
                                <label htmlFor="IsAcademic">Is Academic?</label>
                                <select id="IsAcademic" onChange={onEdit}
                                    value={createDepartment.IsAcademic.toString()}
                                    className="form-select form-select-solid"
                                    data-kt-select2="true"
                                    data-placeholder="Select option"
                                    data-dropdown-parent="#kt_menu_624456606a84b" data-allow-clear="true">
                                    <option value={""}>-select type-</option>
                                    <option value={"1"}>YES</option>
                                    <option value={"0"}>NO</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mt-4">
                                <label htmlFor="IsAwardDegree"> Is Awarding Degree ?</label>
                                <select id="IsAwardDegree" onChange={onEdit}
                                    value={createDepartment.IsAwardDegree.toString()}
                                    className="form-select form-select-solid"
                                    data-kt-select2="true"
                                    data-placeholder="Select option"
                                    data-dropdown-parent="#kt_menu_624456606a84b" data-allow-clear="true">
                                    <option value={""}>-select type-</option>
                                    <option value={"1"}>YES</option>
                                    <option value={"0"}>NO</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group mt-4">
                            <label htmlFor="DepartmentHead">Department Head</label>
                            <select id="DepartmentHead" onChange={onEdit}
                                value={createDepartment.DepartmentHead}
                                className="form-select form-select-solid"
                                data-kt-select2="true"
                                data-placeholder="Select option"
                                data-dropdown-parent="#kt_menu_624456606a84b" data-allow-clear="true">
                                <option value={""}>-select H.O.D-</option>
                                {academicStaff.length > 0 ?
                                    <>
                                        {academicStaff.map((x, y) => {
                                            return (
                                                <option key={y} value={x.StaffID}>{x.StaffName}</option>
                                            )
                                        })}
                                    </>
                                    :
                                    <></>}
                            </select>
                        </div>
                    </div>

                    <div className="form-group pt-2">
                        <button onClick={onSubmit} className="btn btn-primary w-100" id="kt_modal_new_address_submit" data-kt-indicator={isFormLoading} >
                        <span className="indicator-label">Submit</span>
                        <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                        </button>
                    </div>
                </Modal>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        LoginDetails: state.LoginDetails,
        FacultyList: state.FacultyList
    };
};
export default connect(mapStateToProps, null)(Department);
