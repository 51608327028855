import React, { useEffect, useState } from "react";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import Loader from "../../common/loader/loader";
import { useNavigate, useParams } from "react-router";
import { showAlert, showConfirm } from "../../common/sweetalert/sweetalert";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  AdmissionSuccessfulEmailTemplate,
  admissionEmail,
  sendEmail,
  projectName,
  formatDateAndTime,
  projectHREmail, shortCode, projectAddress, projectURL, projectLogo, projectEmail, projectPhone, projectCode, projectViceChancellor,
} from "../../../resources/constants";
import { formatDate } from "@formatjs/intl";
import { data } from "jquery";
import { saveAs } from "file-saver";


function ProcessApplication(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [appInfo, setAppInfo] = useState([]);
  const { applicant } = useParams();
  const navigate = useNavigate();
  const [reason, setReason] = useState(false);
  const [approved, setApproved] = useState(false);
  const [showConditional, setShowCondional] = useState(false);
  const [conditional, setConditional] = useState(false);
  const [semester, setSemester] = useState([]);
  const [courses, setCourses] = useState([]);
  const decisionStaff = props.login;
  if (applicant === "") {
    navigate("/registration/admissions");
  }
  const [Statuscolor, setStatuscolor] = useState('info');
  const [Statustext, setStatustext] = useState('Applicantion is awaiting your decision');
  const app_type = window.location.href.split('/')[3].split('-')[2] === "ug" ? "undergraduate" : "";
  const date = new Date().toISOString().slice(0, 19).replace("T", " ");
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [AdmissionLetter, setAdmissionLetter] = useState([]);
  const [decision, setDecision] = useState({
    applicant_id: applicant,
    rejectReason: "",
    action: "",
    decisionStaff:
      decisionStaff[0].FirstName +
      " " +
      decisionStaff[0].MiddleName +
      " " +
      decisionStaff[0].Surname,
    decisionDate: date,
    appliedDate: "",
    type: "",
    level: "",
    semester: "",
    admissionSemester: props.currentSemester,
    courseCode: "",
    CourseName: "",
    con1: "",
    con2: "",
    con3: "",
    con4: "",
    con5: "",
    url: projectURL.split("//")[1]
  });

  useEffect(() => {
    const getSemesters = async () => {
      await axios
        .get(`${serverLink}registration/admissions/semester`)
        .then((response) => {
          setSemester(response.data);
        });

      await axios.get(`${serverLink}registration/admissions/admission-letter/${applicant}`)
        .then((result) => {
          setAdmissionLetter(result.data)
        })
    };
    getSemesters();
  }, []);

  useEffect(() => {
    const getCourses = async () => {
      axios
        .get(`${serverLink}registration/admissions/courses`)
        .then((response) => {
          setCourses(response.data);
        })
        .catch((ex) => {
          console.error(ex);
        });
    };
    getCourses();
  }, []);

  const getApplicantData = async () => {
    const type = "";

    try {
      await axios
        .get(`${serverLink}registration/admissions/payment/list/${applicant}/${app_type}`)
        .then((response) => {
          setPaymentHistory(response.data)
        });

      await axios
        .get(`${serverLink}registration/admissions/ug/information/${applicant}`)
        .then((response) => {
          setDecision({
            ...decision,
            courseCode: response.data.course[0].CourseCode,
            CourseName: response.data.course[0].CourseName
          })
          const applicantStatus = response.data.course[0].Status;
          const color = applicantStatus === 0 ? 'info'
            : applicantStatus === 2 ? 'success'
                : 'danger'
          const text = applicantStatus === 0 ? 'Application is awaiting your decision '
            : applicantStatus === 2 ? 'Applicantion Accepted'
                : 'Application Rejected'
          setStatustext(text);
          setStatuscolor(color);
          setAppInfo(response.data);
        });
      setIsLoading(false);
    } catch (ex) {
      if (ex.response && ex.response.status === "404")
        return navigate("registration/admissions");
    }
  };
  useEffect(() => {
    getApplicantData();
  }, []);

  const handleChange = (e) => {
    const target = e.target;
    setDecision({
      ...decision,
      [e.target.id]: e.target.value
    });

    if (target.id === "action") {
      if (target.value === "2") {
        setConditional(true);
        setReason(false);
        setApproved(false);
        setShowCondional(false);
      }
      if (target.value === "3") {
        setReason(true);
        setShowCondional(false);
        setApproved(false);
        setConditional(false);
      }
    }
    if (e.target.id === "courseCode") {
      setDecision({
        ...decision,
        [e.target.id]: e.target.value,
        CourseName: courses.filter(x => x.CourseCode === e.target.value)[0].CourseName
      })
    }

    if (target.id === "type") {
      if (target.value === "Conditional") {
        setShowCondional(true);
        setReason(false);
        setApproved(false);
      }

      if (target.value === "Direct Entry") {
        setApproved(true);
        setShowCondional(false);
        setReason(false);

      }
    }
  };

  const handleConditionsChange = (e) => {
    if (e.target.checked === true) {
      setDecision({
        ...decision,
        [e.target.id]: e.target.value
      });
    } else {
      setDecision({
        ...decision,
        [e.target.id]: ""
      });
    }
  }
  const getAdmissionLetter = async () => {
    const sendData = {
      applicantInfo: appInfo.applicant_data,
      applicantCourse: appInfo.course,
      decison: decision,
      school: {
        logo: projectLogo,
        name: projectName,
        address: projectAddress,
        email: projectEmail,
        phone: projectPhone,
        shortCode: shortCode,
        viceChancellor: projectViceChancellor
      }
    }
    try {
      await axios.post(`${serverLink}registration/admissions/create-ug-admission-letter-pdf`, sendData)
        .then(async (result) => {
          if (result.data.message === "success") {
            await axios.get(`${serverLink}registration/admissions/fetch-ug-admission-pdf/${result.data.filename}`, { responseType: 'blob' })
              .then((res) => {
                const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                //saveAs(pdfBlob, `${decision.applicant_id}.pdf`);

              })
          } else {

          }
        })
    } catch (e) {
      console.log('error occured')
    }
  }

  //alansar admission letter
  const getAlansarAdmissionLetter = async () => {
    const sendData = {
      applicantInfo: appInfo.applicant_data,
      applicantCourse: appInfo.course,
      decison: decision,
      school: {
        logo: projectLogo,
        name: projectName,
        address: projectAddress,
        email: projectEmail,
        phone: projectPhone,
        shortCode: shortCode,
        viceChancellor: projectViceChancellor
      }
    }
    try {
      await axios.post(`${serverLink}registration/admissions/al-ansar-create-ug-admission-letter-pdf`, sendData)
        .then(async (result) => {
          if (result.data.message === "success") {
            await axios.get(`${serverLink}registration/admissions/fetch-ug-admission-pdf/${result.data.filename}`, { responseType: 'blob' })
              .then((res) => {
                const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                //saveAs(pdfBlob, `${decision.applicant_id}.pdf`);

              })
          } else {

          }
        })
    } catch (e) {
      console.log('error occured')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = [];
    for (var key in decision) {
      if (decision.hasOwnProperty(key) && key.includes("con")) {
        values.push(decision[key]);
      }
    }
    const decison = decision;
    const checked = [];
    values.map((val) => {
      checked.push(`<li>${val}</li>`);
    });

    if (decision.action.trim() === "") {
      showAlert("EMPTY FIELD", "Please select a decision", "error");
      return false;
    }

    showConfirm("CONFIRM", `Are you sure you want ${decision.action === "2" ? "Approve?" : "Deny?"}`, "warning")
      .then(async (isConfirm) => {
        if (isConfirm) {
          if (applicant !== "") {
            await axios
              .put(`${serverLink}registration/admissions/admission-status`, decision)
              .then((result) => {
                //2 = approve
                //3 = reject
                getApplicantData();
                if (decision.action === "2" && decision.type === "Direct Entry") {
                  if (shortCode === "BAUK") {
                    getAdmissionLetter();
                  }
                  if(shortCode === "AUM"){
                    getAlansarAdmissionLetter();
                  }

                  admissionEmail(
                    appInfo.applicant_data[0].EmailAddress,
                    `${shortCode}/APP/${applicant}`,
                    `${projectName.split('|')[0]} Admission`,
                    "Admission",

                    `${appInfo.applicant_data[0].FirstName} ${appInfo.applicant_data[0].MiddleName !== ""
                      ? appInfo.applicant_data[0].MiddleName
                      : ""
                    } ${appInfo.applicant_data[0].Surname}`,

                    AdmissionSuccessfulEmailTemplate(),
                    `Admissions Office, ${projectName.split('|')[0]}`
                  );
                } else if (
                  decision.action === "2" &&
                  decision.type === "Conditional"
                ) {
                  if (shortCode !== "BAUK") {
                    getAdmissionLetter();
                  }

                  let cons_ = []
                  if (decison.con1 !== "") {
                    cons_.push(...[decison.con1])
                  }
                  if (decison.con2 !== "") {
                    cons_.push(...[decison.con2])
                  }
                  if (decison.con3 !== "") {
                    cons_.push(...[decison.con3])
                  }
                  if (decison.con4 !== "") {
                    cons_.push(...[decison.con4])
                  }
                  if (decison.con5 !== "") {
                    cons_.push(...[decison.con5])
                  }
                  //
                  admissionEmail(
                    appInfo.applicant_data[0].EmailAddress,
                    "",
                    `${projectName.split('|')[0]} Admission`,
                    "Letter of Provisional Admission",
                    `${appInfo.applicant_data[0].FirstName} ${appInfo.applicant_data[0].Middlename > 0
                      ? appInfo.applicant_data[0].Middlename
                      : ""
                    } ${appInfo.applicant_data[0].Surname}`,
                    `<br />Further to your application to study at ${projectName.split("|")[0]}, I am pleased to notify you that you have
                    been offered Conditional Admission into the Degree Programme - <strong style="font-style: italic">${decision.CourseName}</strong> starting from 2022/2023 Academic Session due to begin in September, 2022.<br/>
                   
                    ${cons_.length > 0 &&
                    ` Meanwhile, you will need to provide the following documents to fully confirm your admission: <br/>
                      ${cons_.map((x, i) => {
                      return (
                        `<span key='${i}'><br/>${i + 1}. ${x}</span>`
                      )
                    })
                    }`
                    }  
                    <br/> <br/>                    
                    Kindly login to your <a href="${projectURL}/admission/application/login">application portal</a> to download your admission letter.
                    <br/>
                    Best wishes!<br />&nbsp;<br/>
                    Registrar,<br/>
                    ${projectName.split('|')[0]}<br/>
                    ${projectAddress}`,
                    `Admissions Office ${shortCode}`
                  );
                }

                if (result.data.message === "success") {
                  toast.success("Successfully Approve application");
                  // setTimeout(() => {
                  //   navigate("/registration/admissions");
                  // }, 1000);
                }
              })
              .catch((error) => {
                showAlert(
                  "NETWORK ERROR",
                  "Please check your connection and try again!",
                  "error"
                );
              });
          }
          else {

          }
        }
      })

  };

  const allowEnrolment = async () => {
    toast.info('please wait...')
    const email = {
      email: appInfo.applicant_data[0].EmailAddress,
      subject: `${projectName.split('|')[0]} Admission`,
      title: 'Enrolment',
      name: appInfo.applicant_data[0].FirstName.charAt(0).toUpperCase() + appInfo.applicant_data[0].FirstName.slice(1),
      body: `Your admission at ${shortCode} have been confirmed, click the <a href="${projectURL}/admission/enrolment">link<a/> to fill the enrolment form. 
      <br/><br/>For inquiries, contact ${projectHREmail}`,
      signature: ''
    }
    await axios.patch(`${serverLink}registration/admissions/allow-enrolment/${applicant}`)
      .then((response) => {
        if (response.data.message === "success") {
          toast.success('Applicant allowed to enrol.');
          toast.success('Notification email sent to applicant');
          sendEmail(email.email, email.subject, email.title, email.name, email.body, email.signature)
        }
        else {
          toast.error('try again...')
        }
      }).catch((e) => {
        toast.error('Netwrok error...')
        console.log('NETWORK ERROR')
      })
  }
  return isLoading ? (
    <Loader />
  ) : (
    <div className="d-flex flex-column flex-row-fluid">
      <div className="col-md-12">

        <div className={`notice d-flex bg-light-${Statuscolor} rounded border-${Statuscolor} border border-dashed p-6`}>
          <div className="d-flex flex-stack flex-grow-1">
            <div className="fw-bold">
              <h4 className="text-gray-900 fw-bolder">Application Status</h4>
              <div className="fs-6 text-gray-700">{Statustext}</div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end mt-4">
          {
            AdmissionLetter.length > 0 &&
            <a target={"_blank"} href={`${serverLink}public/uploads/admission-letters/${AdmissionLetter[0].AdmissionLetter}.pdf`} className="btn btn-sm btn-primary">Print Admission Letter</a>
          }
        </div>

        <h3>Personal Information</h3>
        <table className="table">
          <tbody>
            <tr>
              <td className="fw-bolder">Name</td>
              <th>
                {`${appInfo.applicant_data[0].FirstName} ${appInfo.applicant_data[0] > 0
                  ? appInfo.applicant_data[0].Middlename
                  : ""
                  } ${appInfo.applicant_data[0].Surname}`}
              </th>
            </tr>
            <tr>
              <td className="fw-bolder">Email Address</td>
              <th>{appInfo.applicant_data[0].EmailAddress}</th>
            </tr>
            <tr>
              <td className="fw-bolder">Phone Number</td>
              <th>{appInfo.applicant_data[0].PhoneNumber}</th>
            </tr>
            <tr>
              <td className="fw-bolder">Date of Birth</td>
              <th>{formatDateAndTime(appInfo.applicant_data[0].DateOfBirth, "date")}</th>
            </tr>
            <tr>
              <td className="fw-bolder">Gender</td>
              <th>{appInfo.applicant_data[0].Gender}</th>
            </tr>
            <tr>
              <td className="fw-bolder">Nationality</td>
              <th>{appInfo.applicant_data[0].Nationality}</th>
            </tr>
            <tr>
              <td className="fw-bolder">State of Origin</td>
              <th>{appInfo.applicant_data[0].StateOfOrigin}</th>
            </tr>
            <tr>
              <td className="fw-bolder">LGA</td>
              <th>{appInfo.applicant_data[0].LGA}</th>
            </tr>
            <tr>
              <td className="fw-bolder">Religion</td>
              <th>{appInfo.applicant_data[0].Religion}</th>
            </tr>
            <tr>
              <td className="fw-bolder">Occupation</td>
              <th>
                {appInfo.applicant_data[0].Occupation
                  ? appInfo.applicant_data[0].Occupation
                  : "Nil"}
              </th>
            </tr>
            <tr>
              <td className="fw-bolder">Address</td>
              <th>{appInfo.applicant_data[0].Address}</th>
            </tr>
          </tbody>
        </table>
        <hr />
        <h3>O'level Information</h3>
        <table className="table table-row-dashed">
          <thead>
            <tr>
              <th className="fw-bolder">Exam Type</th>
              <th className="fw-bolder">Exam Center</th>
              <th className="fw-bolder">Exam Number</th>
              <th className="fw-bolder">Exam Year</th>
            </tr>
          </thead>
          <tbody>
            {appInfo.olevel_sitting.map((sit, index) => (
              <tr key={index}>
                <td>{sit.ExamsType}</td>
                <td>{sit.ExamsCenter}</td>
                <td>{sit.ExamsNumber}</td>
                <td>{sit.ExaminationYear}</td>
              </tr>
            ))}

            {appInfo.oLevel.map((olevel, index) => (
              <tr key={index}>
                <td>{olevel.ExamsSubject}</td>
                <th>{olevel.Score}</th>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
        {
          projectName.toLocaleLowerCase().includes('olivia') ?
            <>
            </>
            :
            <>
              <h3>Jamb Information</h3>
              <table className="table table-row-dashed">
                <thead>
                  <tr>
                    <th className="fw-bolder">Subjects</th>
                    <th className="fw-bolder">Score</th>
                    <th className="fw-bolder">Matric Number</th>
                    <th className="fw-bolder">Exam Year</th>
                  </tr>
                </thead>
                <tbody>
                  {appInfo.jamb.map((j, index) => (
                    <tr key={index}>
                      <td>{j.SubjectName}</td>
                      <td>{j.SubjectScore}</td>
                      <td>{j.MatricNumber}</td>
                      <td>{j.ExaminationYear}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr />
            </>
        }


        <h3>Guardian Information</h3>
        <table className="table table-row-dashed">
          {
            appInfo.guardian.length > 0 ?
              <tbody>

                <tr>

                  <td className="fw-bolder">Guardian Name</td>
                  <td>
                    {`${appInfo.guardian[0].FirstName} ${appInfo.guardian[0].Middlename > 0
                      ? appInfo.guardian[0].Middlename
                      : ""
                      } ${appInfo.guardian[0].Surname}`}
                  </td>
                  <td className="fw-bolder">Email Address</td>
                  <td>{appInfo.guardian[0].PhoneNumber}</td>
                </tr>
                <tr>
                  <td className="fw-bolder">Phone Number</td>
                  <td>{appInfo.guardian[0].EmailAddress}</td>
                  <td className="fw-bolder">Gender</td>
                  <td>{appInfo.guardian[0].Gender}</td>
                </tr>
                <tr>
                  <td className="fw-bolder">Address</td>
                  <td>{appInfo.guardian[0].Address}</td>
                </tr>
              </tbody>
              :
              <tbody>
                <tr>
                  <td>No Guardian Information</td>
                </tr>
              </tbody>
          }

        </table>
        <hr />
        <h3>Supporting Documents</h3>
        <table className="table table-row-dashed">
          <thead>
            <tr>
              <th className="fw-bolder">Document Name</th>
              <th className="fw-bolder">Action</th>
            </tr>
          </thead>
          <tbody>
            {appInfo.documents.map((doc, index) => (
              <tr key={index}>
                <td>{doc.DocumentType}</td>
                <td>
                  <a
                    href={doc.FileName.includes("simplefileupload.com") ? doc.FileName : `${serverLink}public/uploads/application/document/${doc.FileName}`}
                    target="_blank"
                    className="btn btn-primary"
                  >
                    View
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
        <>
          <h3>Payment History</h3>
          <table className="table table-row-dashed">
            <thead>
              <tr>
                <th className="fw-bolder">Description</th>
                <th className="fw-bolder">Amount</th>
                <th className="fw-bolder">Reference</th>
                <th className="fw-bolder">Payment Document</th>
                <th className="fw-bolder">Date Paid</th>
                <th className="fw-bolder">Action</th>
              </tr>
            </thead>
            <tbody>
              {
                paymentHistory.length > 0 ?
                  <>
                    {
                      paymentHistory.map((j, index) => {
                        const disabled = j.Status.toString() !== "2" ? true : false;
                        const visible = j.Description.toLocaleLowerCase().includes('tuition') === true ? "block" : "none"
                        return (
                          <tr key={index}>
                            <td>{j.Description}</td>
                            <td>{j.AmountPaid}</td>
                            <td>{j.PaymentReference}</td>
                            <td><a className="btn btn-sm btn-primary" target="_blank"
                                   href={j.FilePath.includes("simplefileupload.com") ? j.FilePath : `${serverLink}public/uploads/application/document/${j.FilePath}`}>
                              View Document</a></td>
                            <td>{formatDateAndTime(j.InsertedDate, "date")}</td>
                            <td>
                              <button disabled={disabled} style={{ display: `${visible}` }} className="btn btn-sm btn-primary"
                                onClick={allowEnrolment}>
                                Allow Enrolment
                              </button></td>
                          </tr>
                        )
                      })
                    }
                  </>
                  : <>
                    <tr>
                      <td><h3>No Payment made</h3></td>
                    </tr>
                  </>
              }

            </tbody>
          </table>
          <hr />
        </>

        <h3>Course Applied</h3>
        <h6>{appInfo.course[0].CourseName}</h6>
        <hr />
        <h3>Admission Decision</h3>

        <div className="col-md-12 ">
          <div className="row">
            <form onSubmit={handleSubmit}>
              <div className="col-md-12 fv-row">
                <label className="required fs-6 fw-bold mb-2">Decision</label>
                <select
                  className="form-select"
                  data-control="select2"
                  data-placeholder="Select a Decision"
                  id="action"
                  required
                  onChange={handleChange}
                >
                  <option value="">Select option</option>
                  <option value="2">Approve</option>
                  <option value="3">Reject</option>
                </select>
              </div>
              {conditional ? (
                <div className="col-md-12 fv-row">
                  <div className="col-md-12 fv-row">
                    <label className="required fs-6 fw-bold mb-2">
                      Decision Type
                    </label>
                    <select
                      className="form-select"
                      data-placeholder="Select a Decision type"
                      data-control="select2"
                      id="type"
                      required
                      onChange={handleChange}
                    >
                      <option value="">Select option</option>
                      <option value="Conditional">Conditional</option>
                      <option value="Direct Entry">Direct Entry</option>
                      <option value="Transfer">Transfer</option>
                      <option value="Foundation">Foundation</option>
                    </select>
                  </div>
                </div>
              ) : null}
              {showConditional ? (
                <>
                  <div className="col-md-12 fv-row">
                    <div className="row mt-5">
                      <div className="col-md-3 fv-row">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="High School Transcript"
                            id="con1"
                            onChange={handleConditionsChange}
                          />
                          <label className="form-check-label" htmlFor="con1">
                            High School Transcript
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 fv-row">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="High School Diploma"
                            id="con2"
                            onChange={handleConditionsChange}
                          />
                          <label className="form-check-label" htmlFor="con2">
                            College Transcript
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 fv-row">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="College Transcript"
                            id="con3"
                            onChange={handleConditionsChange}
                          />
                          <label className="form-check-label" htmlFor="con3">
                            College Transcript
                          </label>
                        </div>
                      </div>

                      <div className="col-md-3 fv-row">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="College Degree"
                            id="con4"
                            onChange={handleConditionsChange}
                          />
                          <label className="form-check-label" htmlFor="con4">
                            College Degree
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row mt-5">
                      <div className="col-md-3 fv-row">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="Letter(s) of recommendation"
                            id="con5"
                            onChange={handleConditionsChange}
                          />
                          <label className="form-check-label" htmlFor="con5">
                            Letter(s) of recommendation
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {approved ? (
                <>
                  <div className="row mt-5">
                    <div className="col-md-12  fv-row">
                      <label className="required fs-6 fw-bold mb-2">
                        Decision Level
                      </label>
                      <select
                        className="form-select"
                        data-placeholder="Select a Decision level"
                        data-control="select2"
                        id="level"
                        required
                        onChange={handleChange}
                      >
                        <option value="">Select option</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="300">300</option>
                        <option value="400">400</option>
                        <option value="500">500</option>
                        <option value="600">600</option>
                        <option value="700">700</option>
                        <option value="800">800</option>
                        <option value="900">900</option>
                      </select>
                    </div>
                  </div>
                  <div className="row  mt-5">
                    <div className="col-md-6 fv-row">
                      <label className="required fs-6 fw-bold mb-2">
                        Decision Semester
                      </label>
                      <select
                        className="form-select"
                        data-placeholder="Select a Decision semester"
                        data-control="select2"
                        id="semester"
                        required
                        onChange={handleChange}
                      >
                        <option value="">Select option</option>
                        <option value="First Semester">First Semester</option>
                        <option value="Second Semester">Second Semester</option>
                      </select>
                    </div>
                    <div className="col-md-6 fv-row">
                      <label className="required fs-6 fw-bold mb-2">
                        Admission Semester
                      </label>
                      <select
                        className="form-select"
                        data-placeholder="Select admission semester"
                        id="admissionSemester"
                        required
                        onChange={handleChange}
                      >
                        <option value="">Select option</option>
                        {semester.map((sem, index) => (
                          <option key={index} value={sem.SemesterCode}>
                            {sem.SemesterName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-12 fv-row">
                      <label className="required fs-6 fw-bold mb-2">
                        Course
                      </label>
                      <select
                        className="form-select"
                        data-control="select2"
                        data-placeholder="Select a Course"
                        id="courseCode"
                        value={decision.courseCode}
                        required
                        onChange={handleChange}
                      >
                        <option value="">Select option</option>
                        {courses.map((course, index) => (
                          <option key={index} value={course.CourseCode}>
                            {course.CourseName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </>
              ) : null}
              {reason ? (
                <div className="d-flex flex-column mb-8">
                  <label className="fs-6 fw-bold mb-2">Reason</label>
                  <textarea
                    className="form-control form-control-solid"
                    rows="3"
                    placeholder="Reason for rejection"
                    onChange={handleChange}
                    id="rejectReason"
                    required
                  ></textarea>
                </div>
              ) : null}

              <div className="d-flex flex-column mb-8 mt-8">
                <button type="submit" className="btn btn-primary mt-4">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    login: state.LoginDetails,
    currentSemester: state.currentSemester
  };
};

export default connect(mapStateToProps, null)(ProcessApplication);
