export const serverStatus = "Live";

export const serverLink =
  serverStatus === "Dev"
    ? "http://localhost:4480/"
    : "https://alansaruniversityserver.herokuapp.com/";
    // : "https://smartsourcing.ng/babaahmed-api/";
    // : "https://smartsourcing.ng/olivia-api/";
export const simpleFileUploadAPIKey = "f4aa216109536cd638b21812f5ba7432";

export const projectName = "Al-Ansar University";
export const projectPhone = "+234 803 347 8966";
export const projectEmail = "info@aum.edu.ng"
export const projectAddress = "Al-Ansar University, Maiduguri, Nigeria";

// export const projectName = "Olivia University";
// export const projectPhone = "+234 803 347 8966";
// export const projectEmail = "info@oliviauniversity.com";
// export const projectAddress = "No 4&5, Avenue Mayugi, Mukaza, Bujumbura, Burundi";



