import React, { useEffect, useState } from "react";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { connect } from "react-redux/es/exports";
import Loader from "../../common/loader/loader";
import ReportTable from "../../common/table/report_table";
import { encryptData, formatDate, formatDateAndTime } from "../../../resources/constants";
import { Link } from "react-router-dom";


function JobApplications(props) {
    const [isFormLoading, setIsFormLoading] = useState('off');

    const [facultyList, setFacultyList] = useState(
        props.FacultyList.length > 0 ? props.FacultyList : []
    )
    const [departmentList, setDepartmentList] = useState(
        props.DepartmentList.length > 0 ? props.DepartmentList : []
    )
    const [department, setDepartment] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const columns = ["Name", "Email", "Position", "Department", "Faculty", "Applied On", "Status", "Action"]

    const [data, setData] = useState([])
    const [createApplicant, setApplicant] = useState({
        EntryID: "",
        Position: "",
        Faculty: "",
        Department: "",
        DateApplied: "",
        Status: "",
        InsertedBy: props.LoginDetails[0].StaffID,
    });

    const getData = async () => {
        let faculties_=[]
        await axios.get(`${serverLink}staff/academics/faculty/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    faculties_ = result.data;
                    setFacultyList(result.data)
                }
            })

            let depts_=[]
        await axios.get(`${serverLink}staff/academics/department/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    depts_ = result.data;
                    setDepartmentList(result.data)
                    setDepartment(result.data)
                }
            })


            depts_.length > 0 &&
            await axios.get(`${serverLink}jobs/job-applications/all/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    let rows = [];
                    result.data.map((item, index) => {
                        rows.push([
                        item.FirstName+" "+item.MiddleName+" "+item.Surname,
                        item.EmailAddress,
                        item.Position,
                        depts_.filter(x => x.DepartmentCode.toLowerCase() === item.Department.toLowerCase())[0].DepartmentName,
                        faculties_.filter(x => x.FacultyCode.toLowerCase() === item.Faculty.toLowerCase())[0].FacultyName,
                        formatDateAndTime(item.InsertDate, "date"),
                        <label className={
                            item.Status === "0" ? "badge badge-secondary" :
                                item.Status === "1" ? "badge badge-info" :
                                    item.Status === "2" ? "badge badge-danger" : "badge badge-success"
                        }>{
                            item.Status === "0"? "Pending" : 
                            item.Status === "1"? "Invited": 
                            item.Status === "2"? "Rejected": "Accepted"
                        }</label>,
                        <Link className="btn btn-sm btn-primary" to={`/human-resources/jobs/applications/${encryptData(item.EntryID.toString())}`}><i className="fa fa-pen" /></Link>
                        ])
                    })
                    setData(rows)
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err)
                console.log('NETWORK ERROR');
            });
    }


    useEffect(() => {
        getData()
    }, []);

    return isLoading ? (
        <Loader />
    ) :
        (
            <>
                <div className="card" style={{ borderStyle: 'none', borderWidth: '0px', width:'100%' }}>
                    <div className="">
                        <div className="row col-md-12" style={{width:'100%'}}>
                            <ReportTable columns={columns} data={data} title={"Job Openings"} />
                            {/* <Table data={datatable} /> */}
                        </div>
                    </div>

                </div>
            </>
        )
}
    
        
const mapStateToProps = (state) => {
    return {
        LoginDetails: state.LoginDetails,
        FacultyList: state.FacultyList,
        DepartmentList: state.DepartmentList
    };
};
export default connect(mapStateToProps, null)(JobApplications);
